<template>
  <div class="row h-100 login-wrap">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="col-md-6 col-lg-5 left-login">
      <div class="auth-content col-md-11">
        <div class="card-body">
          <div class="card-title text-center mb-5 font-18">
            <div class="text-center subtitle">
              <img style="width: 25rem;" src="./eroyal icone_dourado_fundo claro@4x.png" alt="logo" class="logo">
            </div>
          </div>
          <div class="text-acesso text-center">
            Faça seu login para <br> acessar a plataforma
          </div>
          <form method="post" @submit.prevent="onSubmit('frmLogin')" data-vv-scope="frmLogin">
            <div class="mb-4">
              <custom-input
                v-model="login.username"
                class="input"
                name="login.username"
                placeholder="E-mail"
                type="text"
                rootClassName="md-form mb-0"
                inputClassName="md-form-control"
                v-validate="{ required: true, email: true }"
                :error="(submitted) ? errors.first('login.username') : ''"
              >
              </custom-input>
            </div>
            <div class="mb-4">
              <custom-input
                v-model="login.password"                        name="login.password"
                placeholder="Senha"
                type="password"
                rootClassName="md-form mb-0"
                inputClassName="md-form-control"
                v-validate="{ required: true }"
                :error="(submitted) ? errors.first('login.password') : ''">
              </custom-input>
              <small v-if="invalid" class="royalc-error-field">Senha incorreta. Tente novamente ou clique em "Esqueceu a senha?".</small>
              <small v-if="invalidNetwork" class="royalc-error-field">
                Algo deu errado!  <router-link :to="{name: 'CheckNetwork'}">{{ $t('login.click-here') }}</router-link> para diagnosticarmos o problema
              </small>
            </div>
            <div class="flexbox check mb-6">
              <label class="checkbox">
                <input type="checkbox" id="remember_me" name="_remember_me" v-model="login.rememberMe"  value="on"><span style="color:#7f7c7c">{{ $t('login.remember-me') }}</span>
              </label>
            </div>
            <div class="btLogin">
              <button class="btn-login btn-log" type="submit">{{ $t('login.login') }}</button>
            </div>
            <h3 class="text-ou">OU</h3>
            <div class="btLogin">
              <router-link :to="{name: 'RequestAccess'}"><button class="btn-login btn-login-solicitar" type="submit">Solicitar Acesso</button></router-link>
            </div>
          </form>
          <div class="text-center mb-2 font-12">
            <br/>
            <span class="login-link-text">
              {{ $t('login.forgot-password') }}
              <router-link :to="{name: 'PasswordResetRequest'}" style="color:#80754E !important">{{ $t('login.click-here') }} </router-link>
            </span>
            <img src="/static/images/login/powered.svg" style="width: 25%; padding-top: 30px" alt="logo" class="logo">
          </div>
          <!--<div class="text-center font-12">-->
            <!--<div>{{ $t('login.do-not-have-an-account') }}-->
              <!--<a class="ml-2" href="javascript;">{{ $t('login.register') }}</a>-->
            <!--</div>-->
          <!--</div>-->
        </div>
      </div>
    </div>

    <div class="hidden-md col-md-6 col-lg-7 col-sm-12 right-login">
      <div class="txtlogin">VOCÊ NO<br> CONTROLE DAS<br> OPERAÇÕES.
        <div class="textSaibaMais"> <a class="textSaibaMais" href=""><u>SAIBA MAIS>></u></a></div>
      </div>
      <div class="right-login-desktop"></div>
      <!-- <div class="wrap-content">
        <div class="subtitle-welcome">
          <img src="/static/images/login/titulo.png" alt="">
          {{ $t('login.content.subtitle') }}
        </div>
        <div class="content-welcome">
          <p>
            {{ $t('login.content.first') }}
            {{ $t('login.content.hightlight') }}
            {{ $t('login.content.last') }}
          </p>
          <a target="_blank" href="http://www.royalcargo.com.br/blog/2019/09/10/portal-web-da-royal-cargo-informacao-em-um-unico-lugar/">
            {{ $t('login.content.link') }} >>
          </a>
          <img class="notebook" src="/static/images/login/notebook.png">
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
// import router from '@/router'
import { loginService } from '@/services/loginService'
import UserService from '@/services/UserService'
import CustomInput from '@/components/Forms/CustomInput.vue'
import VeeValidate from 'vee-validate'
import { locale } from '@/utils/validator'

import './login.css'
import './loginResponsive.css'
import '@/assets/css/custom.css'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import dayjs from 'dayjs'

VeeValidate.Validator.localize({ 'pt_BR': locale })
Vue.use(VeeValidate, { locale: 'pt_BR', fieldsBagName: 'formFields' })

export default {
  name: 'Login',
  metaInfo: {
    titleTemplate: '%s | Login'
  },
  components: {
    CustomInput,
    Loading
  },
  data () {
    return {
      invalid: false,
      invalidNetwork: false,
      isLoading: false,
      fullPage: true,
      BaseURL: process.env.VUE_APP_BaseURL,
      login: {
        username: null,
        password: null,
        rememberMe: false
      },
      submitted: false,
      loading: false
    }
  },
  created () {
    let _this = this
    let token = this.$route.query.token || null

    if (token) {
      _this.isLoading = true
      UserService.confirmAccessRequest(token).then(res => {
        if (res) {
          _this.$router.push({ name: 'Login' })
        }
      }).finally(() => {
        _this.isLoading = false
      })
    }
  },
  methods: {
    applyFirstFilters () {
      let filters = {}
      // hasFilter: false
      filters.filProcesso = null
      filters.filReferenciaCliente = null
      filters.filHBL = null
      filters.filPeriodoEmbarqueDtInicio = null
      filters.filPeriodoEmbarqueDtFim = null
      filters.filPeriodoDesembarqueDtInicio = null
      filters.filPeriodoDesembarqueDtFim = null

      //  weekly monthly yearly
      filters.filPeriodo = 'monthly'
      //  Operação
      filters.filTipoOperacaoModalExportacao = 1
      filters.filTipoOperacaoModalImportacao = 2
      filters.filTipoOperacaoModalNacional = 3

      //  Modal
      filters.filTipoOperacaoModalAereo = 1
      filters.filTipoOperacaoModalMaritimo = 2
      filters.filTipoOperacaoModalTerrestre = 3
      //  Situação
      filters.situacaoAbertos = null
      filters.situacaoEmbarcados = 2

      // Period Date
      filters.filPeriodoDtInicio = (dayjs().add(-1, 'year').date(1).hour(0).minute(0).second(0)).format('YYYY-MM-DDTHH:mm:ss')
      filters.filPeriodoDtFim = dayjs().date(dayjs().daysInMonth()).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss')

      // Cliente
      filters.filCliente = []
      filters.filOrigem = null
      filters.filDestino = null

      this.$store.dispatch('setAppliedFilter', filters)
    },
    addValidate (field) {
      this.$validator.attach(field.fieldId, field.fieldRules)
    },
    forgotPassword () {
    },
    onSubmit (scopeName) {
      let me = this
      me.submitted = true
      me.invalid = false
      me.invalidNetwork = false
      this.$validator.validateAll(scopeName).then((result) => {
        if (result) {
          me.isLoading = true
          // global.instanceApp.$alertSwal.showLoading()
          loginService.login(this.login)
            .then(function (res) {
              if (res === true) {
                me.applyFirstFilters()
                me.$router.push({ name: 'Dashboard' })
              } else if (res === 'redirect') {
                me.applyFirstFilters()
                me.$router.push({ name: 'FirstLogin' })
              } else {
                // @TODO: Criar forma para que o filtro inicial seja aplicado
                if (res.name === 'ProcessList') {
                  let filters = {}
                  filters.filProcesso = res.query.process
                  filters.filReferenciaCliente = null
                  filters.filHBL = null
                  // filter.id_status = null
                  filters.filPeriodoEmbarqueDtInicio = null
                  filters.filPeriodoEmbarqueDtFim = null
                  filters.filPeriodoDesembarqueDtInicio = null
                  filters.filPeriodoDesembarqueDtFim = null
                  filters.filCliente = []
                  // Tipo Requisição
                  filters.filTipoRequisicaoAPI = 2
                  filters.filTipoRequisicaoPortal = 1
                  // Operação
                  filters.filTipoOperacaoModalExportacao = 1
                  filters.filTipoOperacaoModalImportacao = 2
                  filters.filTipoOperacaoModalNacional = 3
                  // Modal
                  filters.filTipoOperacaoModalAereo = 1
                  filters.filTipoOperacaoModalMaritimo = 2
                  filters.filTipoOperacaoModalTerrestre = 3

                  me.$store.dispatch('setAppliedFilter', filters)
                  me.$router.push({ name: res.name, query: res.query, params: res.params })
                } else if (res.hasOwnProperty('name')) {
                  me.$router.push({ name: res.name, params: res.params, query: res.query })
                } else {
                  me.$router.push({ name: 'Dashboard' })
                }
              }
            })
            .catch((err) => {
              if (err.hasOwnProperty('code') && err.code === 401) {
                me.invalid = true
              } else {
                me.invalidNetwork = true
              }
            }).finally(() => {
              me.isLoading = false
            })
        }
      })
    }
  }
}
</script>

<style scoped>
  @media (max-width: 600px) {
    .right-login{
      display: none !important;
    }
    .login-wrap{
      margin-top: -40px;
    }
    .logo{
      margin-bottom: 3px;
    }
  }
  .login-link-text a {
    color: #d1b178 !important;
    margin-bottom:20px
  }

  .royalc-error-field a {
    color: #DC3545 !important;
  }
  .login-link-text a:hover {
    color: #80754e !important;
    margin-bottom:20px
  }

  .login-link-text {
    font-weight: 200 !important;
    font-size: 14px !important;
    color:#7f7c7c
  }

  .content-welcome a {
    background-color: inherit;
    color: #d1b178 !important;
    padding-left: 0;
  }

  .content-welcome a:hover {
    color: #8c6d38 !important;
  }
  .txtlogin{
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .textSaibaMais{
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    display: flex;
    align-items: center;
    letter-spacing: 0.001em;
    text-decoration-line: underline;
    text-transform: uppercase;
    /* BG/White */
    color: #FFFFFF !important;
    padding-top: 20px;
  }
  .btn-login{
    background: #80754E;
    border-radius: 8px;
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: #FFFFFF;
    height: 5vh;
    width: 100%;
    border: 1px solid #80754e
  }
  .btn-login-solicitar{
    background: #FFFFFF;
    color: #80754E;
    border: 1px solid #c5cace;
  }
  .btLogin{
    margin-left: -12px;
    padding-top: 25px;
  }
  .text-ou{
    /* Light/14pt */
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 370;
    font-size: 14px;
    line-height: 20px;
    /* or 139% */
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    /* Font/Light */
    color: #666666;
    margin-bottom: -20px;
    padding-top: 20px;
  }
  .text-acesso{
    /* Light/16pt - M */
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 150;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    margin-top: -30px;
    margin-bottom: 56px;

    /* Font/Dark */
    color: #7f7c7c;;
  }
  .btn-login:hover{
    background-color:#80754E;
    color: #FFFFFF;
  }
  .btn-log:hover{
    background-color:#FFFFFF;
    color: #80754E;
    border-color: #c5cace;
  }
  .mb-0{
    margin-bottom: 1.6rem!important;
  }
</style>
